import React, { useState } from "react";
import Layout from "../components/Layout";
import Breadcrumb from "../components/Breadcrumb";
import ProductDetail from "../components/ProductTab/ProductTabDetail";
import { objectImages } from "../data/book-tab-carousel";
import { objectList } from "../data/recommendedList";
import Slider from "react-slick";
import ProductCard from "../components/ProductCard";
import { Helmet } from "react-helmet";

export default function ObjectTab() {
	const path = [
		{ label: "Inici ", to: "/" },
		{ label: "Ceràmica", to: "/" },
		{ label: "Mug Cels Rasos" },
	];

	const tabs = [{ title: "Descripció" }, { title: "Detalls" }];

	const [open, setOpen] = useState(false);

	const toggle = () => setOpen(!open);

	const SampleNextArrow = (props) => {
		const { onClick } = props;
		return (
			<button
				className="Carousel-btn-right"
				onClick={onClick}
			>
				<i className="i-custom-arrow" />
			</button>
		);
	}

	const renderCarouselItems = () => {
		return objectList && objectList.map((item) => {
			return (
				<div>
					<ProductCard
						image={item.image}
						price={item.price}
						name={item.name}
					/>
				</div>
			)
		})
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<Layout>
			<Helmet>
				<link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
			</Helmet>
			<div className="ObjectTab">
				<div className="ObjectTab-container">
					<Breadcrumb className="ObjectTab-breadcrumb" path={path} />
					<ProductDetail
						images={objectImages}
						title="TASSA CERÀMICA CELS RASOS"
						price="30,00 €"
						description="El gerro rosa Inka és simple i minimalista, però a el mateix temps modern i únic a causa dels mànecs decoratius en cada costat de el pot. Està fet a mà i està fet de ceràmica 100%."
						editorial="RBA LIBROS"
						language="ANGLÈS"
						publication_edition="2019"
						page_number="448"
						isbn="9788491873198"
						tabs={tabs}
						toggle={toggle}
						open={open}
					/>
				</div>
				<section className="ObjectTab-recommended">
					<h3 className="ObjectTab-recommended-title">
						També et pot agradar…
					</h3>
					<div className="ObjectTab-carousel">
						<Slider className="Novelties-slick" {...settings}>
							{renderCarouselItems()}
						</Slider>
					</div>
				</section>
			</div>
		</Layout>
	);
}
